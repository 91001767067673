
import { defineComponent, ComputedRef, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Story } from '../../types';

import { routeNames } from '../../router'

import StoriesArchive from './StoriesArchive.vue';

export default defineComponent({
  components: {
    StoriesArchive,
  },
  setup() {
    const { push } = useRouter()
    const store = useStore();
    store.dispatch('storiesModule/getStories');

    const stories: ComputedRef<Story[]> = computed(
      () => store.getters['storiesModule/readStories']
    );

    const handleTeaserClick = (story: Story) => {
      store.dispatch('storiesModule/selectArchivedStory', story)
      push(routeNames.STORIES_HOME)
    }

    onMounted(() => {
      // Reset archived story selection when navigating to this page
      store.dispatch('storiesModule/unselectArchivedStory')
    })

    return {
      stories,
      handleTeaserClick,
    };
  },
});
