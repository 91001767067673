
import merge from 'lodash/merge';
import { defineComponent, PropType, computed } from 'vue';

import StoriesLogo from '../../components/StoriesLogo/StoriesLogo.vue';
import LinkButton from '@/components/LinkButton/LinkButton.vue';

import ArchiveCarousel from '@/modules/stories/components/ArchiveCarousel/ArchiveCarousel.vue';
import StoryIntroblock from '@/modules/stories/components/StoryModules/StoryIntroblock.vue';

import { Story } from '../../types';
import { routeNames } from '../../router';

export default defineComponent({
  components: {
    StoriesLogo,
    LinkButton,
    ArchiveCarousel,
    StoryIntroblock,
  },
  props: {
    stories: {
      type: Object as PropType<Story[]>,
      default: [],
    },
    handleTeaserClick: {
      type: Function as PropType<(story: Story) => void>,
      required: true,
    },
  },
  setup(props) {
    const teasers = computed(() =>
      props.stories.reduce((acc, story) => {
        const categoryList = acc[story.category] || [];
        return merge(acc, { [story.category]: [...categoryList, story] });
      }, {})
    );

    return {
      teasers,
      storiesHomeUrl: routeNames.STORIES_HOME,
    };
  },
});
